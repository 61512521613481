import React from "react";
import TaskSwipe from "./TaskSwipe";
import {TaskData} from "./App";

type TasksListProps = {
    tasks: Array<TaskData>;
};

const TasksList: React.FC<TasksListProps> = ({ tasks }) => {
    const [currentTaskIndex, setCurrentTaskIndex] = React.useState(0);

    const onSwipeUp = () => {
        setCurrentTaskIndex(currentTaskIndex + 1);
    };

    const onSwipeDown = () => {
        setCurrentTaskIndex(currentTaskIndex - 1);
    };
    return (

            <TaskSwipe
                tasks={tasks}
                currentTaskIndex={currentTaskIndex}
                onSwipeUp={onSwipeUp}
                onSwipeDown={onSwipeDown}
            />

    );
};

export default TasksList;
