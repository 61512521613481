import {useSwipeable } from 'react-swipeable';
import React from "react";
import Task from "./Task";
import {TaskData} from "./App";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";

type TaskSwipeProps = {
    tasks: Array<TaskData>;
    currentTaskIndex: number;
    onSwipeUp: () => void;
    onSwipeDown: () => void;
};


const TaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    text-align: center;
  height: 70%;
`;

const ArrowButton = styled.button`
  // Your styles for the arrow buttons go here
  width: 100%;
  height: 4em;
  background-color: var(--pastel-yellow);
  border: none;
  border-radius: 1em;
`;

const TaskSwipe: React.FC<TaskSwipeProps> = ({ tasks, currentTaskIndex, onSwipeUp, onSwipeDown }) => {
    const handlers = useSwipeable({
        onSwipedUp: onSwipeUp,
        onSwipedDown: onSwipeDown,
    });

    const previousTask = tasks[currentTaskIndex - 1];
    const currentTask = tasks[currentTaskIndex];
    const nextTask = tasks[currentTaskIndex + 1];

    return (
        <TaskWrapper {...handlers}>
            <div>
                <ArrowButton onClick={onSwipeDown} disabled={!previousTask}>
                    <FontAwesomeIcon icon={faArrowUp} />
                </ArrowButton>
            </div>
            <div>
            {previousTask && <Task task={previousTask} size="small" />}
            {currentTask && <Task task={currentTask} size="large" />}
            {nextTask && <Task task={nextTask} size="small" />}
            </div>
                <div>
                <ArrowButton onClick={onSwipeUp} disabled={!nextTask}>
                    <FontAwesomeIcon icon={faArrowDown} />
                </ArrowButton>
            </div>
        </TaskWrapper>
    );
};

export default TaskSwipe;
