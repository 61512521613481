import React from "react";
import {TaskData} from "./App";

type TaskProp = {
    task: TaskData;
    size: 'small' | 'medium' | 'large';
};


const Task: React.FC<TaskProp> = ({task, size}) => {
    switch (size) {
        case "small":
            return <h4>{task.description}</h4>;
        case "medium":
            return <h3>{task.description}</h3>;
        case "large":
            return <h2>{task.description}</h2>;

    }
    return (<div>
        {task.description}
    </div>);
};

export default Task;
