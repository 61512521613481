import TasksList from "./TasksList";
import React from "react";
import {DayData} from "./App";
import styled from "styled-components";

type DayProps = {
    tasks: DayData;
    onNextDay: () => void;
    onPreviousDay: () => void;
};

const FixedWithDiv = styled.div`
  width: 100%;
  margin: 3em
`;

const LeftRightButtons = styled.div`
  width: 100%;
  display: inline-flex;
justify-content: space-between;
`;

const DayButton = styled.button`
border: none;
border-radius: 1em;
padding: 1em;
`;

const Day: React.FC<DayProps> = ({tasks, onNextDay, onPreviousDay}) => {
    return (
        <FixedWithDiv>
            <LeftRightButtons>
                <DayButton onClick={onPreviousDay}>Previous Day</DayButton>
                <span/>
                <DayButton onClick={onNextDay}>Next Day</DayButton>
            </LeftRightButtons>
            <h1 style={{textAlign: "center"}}>{tasks.description}</h1>
            <TasksList tasks={tasks.tasks}/>
        </FixedWithDiv>
    );
};

export default Day;
