import React, {useEffect} from "react";
import Day from "./Day";
import relaxation from "./relaxation-protocol.json"
import styled from "styled-components";

export type TaskData = {
    order: number, description: string
}
export type DayData = {
    day: number, description: string, tasks: Array<TaskData>
}

const Styles = styled.div`
  --pastel-pink: #FFB6C1;
  --pastel-blue: #ADD8E6;
  --pastel-green: #98FB98;
  --pastel-yellow: #FFFF99;
  --pastel-purple: #DDA0DD;
  --pastel-orange: #FFB347;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  //align-items: center;
  height: 100vh; /* Adjust as needed */
  width: 100%; /* Adjust as needed */

  background-color: var(--pastel-purple)
`;

function useLocalStorage<S> (key: string, initialState: S) {
    const [state, setState] = React.useState<S>(initialState);
    const [initialLoad, setInitialLoad] = React.useState(true);

    useEffect(() => {
        let storedState = localStorage.getItem(key);
        if (!storedState) return;
        setState(JSON.parse(storedState));
    }, [key]);

    useEffect(() => {
        if (state === initialState && initialLoad) return;
        localStorage.setItem(key, JSON.stringify(state));
        setInitialLoad(false);
    }, [initialState, key, state]);

    return [state, setState] as const;
}

const App: React.FC = () => {
    const [tasks] = React.useState<Array<DayData>>(relaxation.days);
    const [currentDay, setCurrentDay] = useLocalStorage<number>("current-day", 1);

    const onNextDay = () => {
        if (tasks.filter(it => it.day === currentDay + 1).length > 0)
        setCurrentDay(currentDay + 1);
    };
    const onPreviousDay = () => {
        if (tasks.filter(it => it.day === currentDay - 1).length > 0)
        setCurrentDay(currentDay - 1);
    };

    const currentTasks = tasks.filter((task) => task.day === currentDay)[0];

    return (
        <Styles>
            <Container>
                <Day tasks={currentTasks} onNextDay={onNextDay} onPreviousDay={onPreviousDay}/>
            </Container>
        </Styles>
    );
};

export default App;
